// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails";
import "./controllers";
import "justified-layout"
import { Fancybox } from "@fancyapps/ui";

import "trix"
import "@rails/actiontext"

document.addEventListener("turbo:load", function() {
    Fancybox.bind('[data-fancybox]');
    console.log('hi');
});